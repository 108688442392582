import * as React from "react"
// import { useState } from "react"

import { Link } from "gatsby"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
// import PageHero from "../../components/template-partials/page-hero"
import FooterCrown from "../../components/template-partials/footer-crown"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import dykKit from "../../images/dyk-kit.png"
import dykKitBG from "../../images/dyk-kit-bg.png"
import download1 from "../../images/downloads/download1.png"
import download2 from "../../images/downloads/download2.png"
import download3 from "../../images/downloads/download3.png"
import download4 from "../../images/downloads/download4.png"
import downloadIcon from "../../images/download_icon.svg"
import QuestionsSM from "../../components/template-partials/questions-sm"
import linkOutIcon from "../../images/link_out_bold.svg"
import hrImage from "../../images/hr-image.svg"

import {
  faAngleRight,
  // faDownload,
  // faArrowToBottom,
  // faExternalLinkAlt,
  // faPlus,
  // faMinus,
} from "@fortawesome/free-solid-svg-icons"

const QuestionsSMBlockButtons = [
  {
    text: "See Peer Directory",
    to: "https://www.smdirectory.com/",
    icon: linkOutIcon,
    newTab: true,
    modalLinkBp: true,
  },
]
const DownloadsPage = () => (
  <Layout route="sm-resources/downloads">
    <Seo
      page="test-kit"
      title="SM Downloadable Resources"
      description="Read and download information about systemic mastocytosis (SM), including testing, disease diagnosis, and understanding the burden of indolent SM."
    />
    <div className="row lg_mt-3 mt-1 align-center medium-align-left">
      <div className="columns small-12 large-12">
        <h1 className="color--purple text--watch-quinn">
          Downloadable systemic mastocytosis (SM) resources for you and your
          practice
        </h1>

        <p className="mt-3 bodyP purple">
          These informative guides are intended to provide more information on
          the diagnosis and management of patients living with SM.
        </p>
      </div>
    </div>

    <div className="row lg_mt-1 mt-1 lg_mb-2 mb-2 align-center medium-align-left">
      <div className="downloadRow mobile-padding">
        <div className="downloadInner ">
          <img
            alt="Download indolent systemic mastocytosis (ISM) brochure"
            src={download1}
            className="downlaodImg"
            width="100%"
            height="auto"
          />
          <p className="bold downloadHeader">
            Understanding the Burden of ISM Brochure
          </p>
          <p className="tightened-line-height">
            Explore the potential impact of indolent systemic mastocytosis (ISM)
            beyond the symptoms and why patients living with ISM may not be
            sharing their full disease burden.
          </p>
          <a
            href="/More-to-Indolent-Disease-Brochure_USDSA24.0002.pdf"
            target="_blank"
            className="btn downloadBtn no-ext mustard_hover"
          >
            <span>Download Brochure</span>{" "}
            <img
              alt="Download Icon"
              src={downloadIcon}
              width="100%"
              height="auto"
            />
          </a>
        </div>
        <div className="downloadInner ">
          <img
            alt="Indolent systemic mastocytosis (ISM) diagnostic criteria and skin findings"
            src={download2}
            className="downlaodImg"
            width="100%"
            height="auto"
          />
          <p className="bold downloadHeader">Skin Findings in SM Flashcard</p>
          <p className="tightened-line-height">
            Review the various skin symptoms associated with SM and diagnostic
            criteria.
          </p>
          <a
            href="/DermatologyFlashcardUSDSA230004.pdf"
            target="_blank"
            className="btn downloadBtn no-ext mustard_hover"
          >
            <span>Download Flashcard</span>{" "}
            <img
              alt="Download Icon"
              src={downloadIcon}
              width="100%"
              height="auto"
            />
          </a>
        </div>
      </div>
      <div className="downloadRow mobile-padding">
        <div className="downloadInner ">
          <img
            alt="Download KIT D816V testing lab sheet"
            src={download3}
            className="downlaodImg"
            width="100%"
            height="auto"
          />
          <p className="bold downloadHeader">
            KIT Testing Commercial Lab Sheet
          </p>
          <p className="tightened-line-height">
            Use this list to find a lab offering high-sensitivity KIT D816V
            assays, a critical part of confirming a diagnosis of SM.
          </p>
          <a
            href="/kit-testing-commercial-lab-sheet.pdf"
            target="_blank"
            className="btn downloadBtn no-ext mustard_hover"
          >
            <span>Download Lab sheet</span>{" "}
            <img
              alt="Download Icon"
              src={downloadIcon}
              width="100%"
              height="auto"
            />
          </a>
        </div>
        <div className="downloadInner ">
          <img
            alt="Download role of hematopathologist in SM brochure"
            src={download4}
            className="downlaodImg"
            width="100%"
            height="auto"
          />
          <p className="bold downloadHeader">
            Role of the Hematopathologist in SM Brochure
          </p>
          <p className="tightened-line-height">
            A guide to the integral role a hematopathologist plays in the
            accurate diagnosis of SM.
          </p>
          <a
            href="/PME-SM-Brochure-Role-of-the-Hematopathologist-(USBP-DASM-22-011).pdf"
            target="_blank"
            className="btn downloadBtn no-ext mustard_hover"
          >
            <span>Download brochure</span>{" "}
            <img
              alt="Download Icon"
              src={downloadIcon}
              width="100%"
              height="auto"
            />
          </a>
        </div>
      </div>
    </div>

    <div className="row lg_mt-2 mt-2  medium-uncollapse">
      <div className="columns">
        <div
          className="hero-breaker hero-breaker--progress hero-breaker--progress--downloads"
          style={{ "--bg-image": `url(${dykKitBG})` }}
        >
          <div className="row align-middle small-col-reverse">
            <div
              className="columns hero-breaker--person hero-breaker--person--left hero-breaker--person--kit small-12 large-5"
              style={{ "--bg-image": `url(${dykKit})` }}
              title="Woman coughing"
            >
              <span className="visually-hidden">Woman coughing</span>
            </div>
            <div className="columns small-12 large-7 text-left mt-4">
              <div className="row">
                <div className="columns large-10">
                  <p className="color--purple text--watch-quinn h1 mb-2">
                    Did you know? <br className="show-for-large" />
                    Resources are available for your patients
                  </p>
                  <p className="color--purple bodyP lead">
                    There is an informational website dedicated to patients
                    living with SM. Direct your patients to{" "}
                    <strong className="underline">ItsSMthing.com</strong>, where
                    they will find educational resources, supportive
                    communities, patient stories, and more.
                  </p>
                  <Link
                    to="https://itssmthing.com/"
                    target="_blank"
                    title="Case studies"
                    className="btn btn--mustard btn--small-fw lg_mt-1 mt-1 no-ext"
                  >
                    VISIT ITSSMTHING.COM{" "}
                    <img
                      alt=""
                      className="main-header__logo dropdown_link_out_icon"
                      src={linkOutIcon}
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <QuestionsSM
      hrImage={hrImage}
      text="SM Provider Peer Directory"
      body_text="An online resource that lists the contact information of volunteer healthcare providers who have attested that they have experience managing patients living with SM and are willing to independently connect with peers who have questions about their medical experience."
      QuestionsSMBlockButtons={QuestionsSMBlockButtons}
    />

    <div className="row lg_mt-7 mt-7 lg_mb-7 mb-7 align-center medium-align-left">
      <div className="columns small-12 medium-expand text-right">
        <p className="h4 color--purple">
          See advocacy resources for your patients
        </p>
        <Link
          className="btn btn--outline btn--icon uppercase mt-1"
          to="/sm-resources/advocacy/"
        >
          ADVOCACY GROUPS <FontAwesomeIcon className="" icon={faAngleRight} />
        </Link>
      </div>
    </div>

    <FooterCrown type="home" />
  </Layout>
)

export default DownloadsPage
